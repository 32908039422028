import React from 'react'
import './WhatsappIntegration.css';
import whatsapp from '../images/whatsapp_integration.png'

const WhatsappIntegration = () => {
    return (
        <>
            <a href="https://wa.me/917979737168?text=Hello! I want to enquiry about" target="_blank" rel="noreferrer">
                <img src={whatsapp} alt="Whatsapp integration" className="whatsapp_float" />
            </a>
            {/* <div class="elfsight-app-e9d86cd3-ed4c-4cc2-a9d1-33fc365954e6"></div> */}
        </>
    )
}

export default WhatsappIntegration;