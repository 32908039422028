import React from 'react'
import './product_main.css';
import img1 from '../../../images/public_address.png';
import Footer from '../../Footer';
import { Link } from 'react-router-dom';
const PublicAddress = () => {
    return (
        <>
            <div className="service_main">
                <section className="services_detail">
                    <div className="about_us">
                        <div className="row2">
                            <div className="about_col">
                                <img src={img1} alt="about us" />
                            </div>
                            <div className="about_col detail_section">

                                <h1>Public Address System</h1>

                                <p className="mentor_details">A public address system (PA system) is an electronic system comprising microphones, amplifiers, loudspeakers, as well as other related equipment It improves the perceived volume (loudness) of a human voice, a musical instrument, or any other acoustic sound source, as well as recorded sound or music. PA systems are used in any public space where an announcer, speaker, or other voice must be audible from away or over a wide area. Sports stadiums, public transit vehicles and services, and live or recorded music venues and events are all examples of standard applications. A PA system may contain multiple microphones or other sound sources, a mixing console for combining and modifying multiple sources, and multiple amplifiers and loudspeakers for increased volume or wider distribution.</p>

                            </div>
                        </div>
                        <div className="product-enquire-btn">
                            <button className='enqire_more_btn'><Link to="/contact-us" className='enquire-more'>Enquire</Link></button>
                        </div>
                    </div>
                </section>
                <Footer />
            </div>
        </>
    )
}

export default PublicAddress
