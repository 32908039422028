import React from 'react'
import './product_main.css';
import img1 from '../../../images/fire-sussepsion.jpg';
import Footer from '../../Footer';
import { Link } from 'react-router-dom';
const FireSuppression = () => {
  return (
    <>
      <div className="service_main">
        <section className="services_detail">
          <div className="about_us">
            <div className="row2">
              <div className="about_col">
                <img src={img1} alt="about us" />
              </div>
              <div className="about_col detail_section">

                <h1>Fire Suppression Systems</h1>

                <p className="mentor_details">Fire Suppression Systems are detection and security systems that can be installed in server rooms, phone rooms, archives, and other high-value areas. Different types of fire suppression systems can be supplied, installed, tested, and commissioned by AFS. . AFS will have an annual maintenance service to ensure that the fire suppression systems are in good working order.</p>

                <ul className="mentor_details">
                  <li className='points'>FM 200 Gas Suppression Systems</li>
                  <li className='points'>Nitrogen Gas Systems</li>
                  <li className='points'>Gas Flooding System</li>
                </ul>
              </div>
            </div>
            <div className="product-enquire-btn">
              <button className='enqire_more_btn'><Link to="/contact-us" className='enquire-more'>Enquire</Link></button>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </>
  )
}

export default FireSuppression
