import React from 'react';
import Navbar from './components/Navbar';
import './App.css';
import Home from './components/pages/Home';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import AboutUs from './components/pages/AboutUs';
import BannerTop from './components/BannerTop';
import Client from './components/pages/Client';

import Products from './components/pages/Products.js';
import FireAlarm from './components/pages/Products/FireAlarm';
import FireFighting from './components/pages/Products/FireFighting';
import FireSuppression from './components/pages/Products/FireSuppression';
import AccessControl from './components/pages/Products/AccessControl';
import CctvCamera from './components/pages/Products/CctvCamera';
import FireExtinguishers from './components/pages/Products/FireExtinguishers';
import DustSuppression from './components/pages/Products/DustSuppression';
import PublicAddress from './components/pages/Products/PublicAddress';

import FireRisk from './components/pages/Services/FireAnalysis';
import Consultancy from './components/pages/Services/Consultancy';
import Installation from './components/pages/Services/Installation';
import SystemDesign from './components/pages/Services/SystemDesign';
import EquipmentProcurement from './components/pages/Services/EquipmentProcurement';
import AnnualMaintainance from './components/pages/Services/AnnualMaintainance';
import FireHazard from './components/pages/Services/FireHazard';
import MainPage from './components/pages/Services/MainPage';

import Industries from './components/pages/Industries/Industries';
import CommercialBuildings from './components/pages/Industries/CommercialBuildings'
import Multiplex from './components/pages/Industries/Multiplex'
import ServerRooms from './components/pages/Industries/ServerRooms'
import IndustrialCompanies from './components/pages/Industries/IndustrialCompanies';
import Hotels from './components/pages/Industries/Hotels';
import School from './components/pages/Industries/School';

import Career from './components/pages/Career';

import ContactUs from './components/pages/Contact/ContactUs';
import RanchiOffice from './components/pages/Contact/RanchiOffice';
import PatnaOffice from './components/pages/Contact/PatnaOffice';
import DelhiOffice from './components/pages/Contact/DelhiOffice';

import CookieConsent from "react-cookie-consent";
import Whatsapp from './components/WhatsappIntegration';
import ScrollTop from './components/ScroolTop';
import ToTop from './components/ToTop';
import VoiceglowWidget from "./components/VoiceglowWidget"

function App() {
  return (
    <Router>
      <ToTop />
      <CookieConsent
        enableDeclineButton flipButtons
        debug={false}
        contentStyle={{ fontSize: "1.2rem", color: "black", margin: "0" }}
        style={{ background: "lightgrey", width: "auto", height: "auto", justifyContent: "none", display: "inline-flex", padding: "10px" }}
        buttonStyle={{ background: 'green', color: 'white' }}
        buttonText="Accept!"
        expire={365}>
        We use cookies in our website for better user experience
      </CookieConsent>
      <ScrollTop />
      <BannerTop />
      <Navbar />
      <Whatsapp></Whatsapp>
      <VoiceglowWidget />
      <Switch>
        <Route path='/' exact component={Home} />
        <Route path='/aboutus' exact component={AboutUs} />

        <Route path='/products' exact component={Products} />
        <Route path='/fire-alarm-systems' component={FireAlarm} />
        <Route path='/fire-fighting-systems' component={FireFighting} />
        <Route path='/fire-suppression-systems' component={FireSuppression} />
        <Route path='/access-control-system' component={AccessControl} />
        <Route path='/cctv-camera' component={CctvCamera} />
        <Route path='/fire-extinguishers' component={FireExtinguishers} />
        <Route path='/dust-Suppression-system' component={DustSuppression} />
        <Route path='/public-address-system' component={PublicAddress} />

        <Route path='/services' exact component={MainPage} />
        <Route path='/fire-hazard' component={FireHazard} />
        <Route path='/fire-risk-assessments' component={FireRisk} />
        <Route path='/installation-and-commissioning' component={Installation} />
        <Route path='/consultancy-training' component={Consultancy} />
        <Route path='/system-design-and-engineering' component={SystemDesign} />
        <Route path='/equipment-procurement-supply' component={EquipmentProcurement} />
        <Route path='/annual-maintain-contract' component={AnnualMaintainance} />

        <Route path='/industries' exact component={Industries} />
        <Route path='/commercial-buildings' component={CommercialBuildings} />
        <Route path='/multiplex' component={Multiplex} />
        <Route path='/server-rooms' component={ServerRooms} />
        <Route path='/industrial-companies' component={IndustrialCompanies} />
        <Route path='/hotels' component={Hotels} />
        <Route path='/school' component={School} />

        <Route path='/career' exact component={Career} />
        <Route path='/client' exact component={Client} />

        <Route path='/contact-us' exact component={ContactUs} />
        <Route path='/ranchi-office' component={RanchiOffice} />
        <Route path='/patna-office' component={PatnaOffice} />
        <Route path='/delhi-office' component={DelhiOffice} />
      </Switch>

    </Router>
  );
}

export default App;
