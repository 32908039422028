import React from 'react'
import './BannerTop.css';
const BannerTop = () => {
    return (
        <>
            <div className="banner_section">
                <div className="banner_content">
                    <p><span>For Any Queries - </span>&nbsp;Call: <a href="tel:+91 7979737168"> +91 797 973 7168</a></p>
                    <p className="BannerEmail"><a href="mailto: info@abhifiresafety.com">Email: info@abhifiresafety.com</a></p>
                </div>
            </div>
        </>
    )
}

export default BannerTop;