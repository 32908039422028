import React from 'react';
import './FireHazard.css';
import imges1 from '../../../images/Fire-Hazard-Analysis.jpg';
import Footer from '../../Footer';
import { Link } from 'react-router-dom';

const FireHazard = () => {
    return (
        <>
            <div className="service_main">
                <section class="services_detail">
                    <div class="about_us">
                        <div class="row2">
                            <div class="about_col">
                                <img src={imges1} alt="about us" />
                            </div>
                            <div class="about_col detail_section">

                                <h1>Fire Hazard Analysis</h1>

                                <p class="mentor_details">AFS provides fire hazard analysis, fire safety analysis, and other fire hazard assessment and consulting services as required by code, facility-specific standards, and insurance-related requirements. A fire hazard analysis (FHA) is a method of evaluating the hazards present and subsequent consequence potential at a processing plant or storage facility that requires an in-depth assessment of fire risk due to significant quantities of flammable materials.</p>

                                <p class="mentor_details">These assessments are based on specific review criteria that are dependent upon the types of hazards being assessed, as well as commodities stored and facility processes.</p>

                                <p class="mentor_details">Each assessment conducted by AFS is completed by a professional fire protection engineer with knowledge of fire risk, fire safety, consequence analysis, and other hazard analysis techniques.</p>
                            </div>
                        </div>
                        <div className="product-enquire-btn">
                            <button className='enqire_more_btn'><Link to="/contact-us" className='enquire-more'>Enquire</Link></button>
                        </div>
                    </div>
                </section>
                <Footer />
            </div>
        </>
    )
}

export default FireHazard;