import React from 'react';
import './CommercialBuildings.css';
// import imges1 from '../../../images/system-design-engineering.jpeg';
import industry4 from '../../../images/08.jpg';
import Footer from '../../Footer';
import { Link } from 'react-router-dom';

const IndustrialCompanies = () => {
    return (
        <>
            <div className="service_main">
                <section class="services_detail">
                    <div class="about_us">
                        <div class="row2">
                            <div class="about_col">
                                <img src={industry4} alt="about us" />
                            </div>
                            <div class="about_col detail_section">

                                <h1>Industrial Companies</h1>

                                <p class="mentor_details">Industrial Fires and Explosions cost companies and governments billions of rupees every year apart from the loss of life, which can’t be described in monetary terms. These fires not only result in huge loss of life and property but also disrupt production in the industry.</p>

                                <p class="mentor_details">There could be several causes for industrial fire accidents including:</p>

                                <ul>
                                    <li>Leakage/Spillage of Flammable/Combustible Material</li>
                                    <li>Electrical Short-circuiting/Overloading</li>
                                    <li>Overheating of bare surfaces/Heater/Electrical Lamps/Furnaces</li>
                                    <li>Welding, Cutting, Soldering and other Hot Work</li>
                                    <li>Chemical Reaction/Runway Reaction</li>
                                    <li>Frictional Heat/Spark</li>
                                    <li>Spontaneous Combustion, Dust Explosion etc</li>
                                    <li>Naked Live Wires</li>
                                </ul>
                            </div>
                        </div>
                        <div className="more_details">
                            <p>Some of the tips to prevent fire accidents in Industrial Companies are below:
                            </p>

                            <ul>
                                <li><span>Ensure your Equipment to Tackle Such Fires are Accessible</span> - Equipment like control panels, sprinklers, firefighting gear, and emergency exits must never be hampered. Examine clearances while hoarding up anything. Arrange emergency exit lights and exit signage so that the employees will have a clear view of where to escape from.</li>
                                <li><span>Test out the Fire extinguishers and make sure they are properly planted</span> - Make routine check-ups on all the fire extinguishers at least every month since precaution is crucial in such cases. Make sure every person who is on the industry premises is well-trained to use the fire extinguisher.</li>
                                <li><span>Put up Signs and Contact Information for Emergencies</span> - Ensure that emergency numbers and the address of the premises are posted. Vital information should be mentioned in a sheet at adequate places. Also, make sure exit signs are visible properly.</li>
                                <li><span>An Evacuation Plan must be in place for any Contingencies</span> - Communicate to all the employees about the spot where they can gather after evacuation. They all should be well versed with company policies in case of contingencies. Mock drills can help the employees in getting acquainted with the kind of response required during any fire mishaps.</li>
                            </ul>

                        </div>
                        <div className="product-enquire-btn">
                            <button className='enqire_more_btn'><Link to="/contact-us" className='enquire-more'>Enquire</Link></button>
                        </div>
                    </div>
                </section>
                <Footer />
            </div>
        </>
    )
}

export default IndustrialCompanies;