import React from 'react'
import './product_main.css';
import img1 from '../../../images/fire-fighting.jpg';
import Footer from '../../Footer';
import { Link } from 'react-router-dom';
const FireFighting = () => {
  return (
    <>
      <div className="service_main">
        <section className="services_detail">
          <div className="about_us">
            <div className="row2">
              <div className="about_col">
                <img src={img1} alt="about us" />
              </div>
              <div className="about_col detail_section">

                <h1>Fire Fighting Systems</h1>

                <p className="mentor_details">Automatic and manual firefighting devices should be used to protect ourselves and our property from fire. AFS  is a company that specializes in the design, supply, installation, testing, and commissioning of various types of fire suppression systems. AFS will have an annual maintenance service to ensure that the fire suppression systems are in good working order.</p>

                <ul className="mentor_details">
                  <li className='points'>Fire Pumps & Ancillary Works</li>
                  <li className='points'>Fire Hydrant System</li>
                  <li className='points'>Automatic Water Based Sprinkler System</li>
                  <li className='points'>Wet Riser Systems</li>
                  <li className='points'>Dry Riser Systems</li>
                  <li className='points'>Vasda Fire Systems</li>
                  <li className='points'>High Velocity Water Spray Systems</li>
                  <li className='points'>Medium Velocity Water Spray Systems</li>
                  <li className='points'>Water Curtain Systems</li>
                  <li className='points'>Passive Fire Systems</li>
                  <li className='points'>Hand Appliances (Fire Extinguishers)</li>
                </ul>
              </div>
            </div>
            <div className="product-enquire-btn">
              <button className='enqire_more_btn'><Link to="/contact-us" className='enquire-more'>Enquire</Link></button>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </>
  )
}

export default FireFighting
