import React from 'react';
import './Industries.css';
import { Link } from 'react-router-dom';
import industry1 from '../../../images/05.jpg';
import industry2 from '../../../images/06.jpg';
import industry3 from '../../../images/07.jpg';
import industry4 from '../../../images/08.jpg';
import industry5 from '../../../images/10.jpg';
import industry6 from '../../../images/09.jpg';
import smoke from '../../../images/smoke.mp4';
import Footer from '../../Footer';

export default function industriess() {
  return (
    <>
      <div id="container">
        <div className="service">
          <video autoPlay muted loop>
            <source src={smoke} type="video/mp4" />
          </video>
          <h1>
            <span>I</span>
            <span>N</span>
            <span>D</span>
            <span>U</span>
            <span>S</span>
            <span>T</span>
            <span>R</span>
            <span>I</span>
            <span>E</span>
            <span>S</span>
          </h1>
          <p>Serving all spheres</p>
        </div>

      </div>
      <div className="industries-container">
        <div className="industries-row">
          <div className="industries-image">
            <img src={industry1} alt="" />
            <div className="details">
              <h2><span>Commercial Buildings</span></h2>
              <p>Buildings are growing vertically taller as a result of increased urbanisation and space constraints, which makes fire safety and rescue more...</p>
              <div className="more">
                <button className='contact_btn read_more_btn '><Link to='/commercial-buildings' className='read-more'><span>Read More</span></Link></button>
              </div>
            </div>
          </div>

          <div className="industries-image">
            <img src={industry2} alt="" />
            <div className="details">
              <h2><span>Server Rooms</span></h2>
              <p>One of the most expensive and valuable parts of any organisation are the server rooms. Your company's IT infrastructure is essential given our growing reliance on technology...</p>
              <div className="more">
                <button className='contact_btn read_more_btn'><Link to="/server-rooms" className='read-more'><span>Read More</span></Link></button>
              </div>
            </div>
          </div>

          <div className="industries-image">
            <img src={industry3} alt="" />
            <div className="details">
              <h2><span>Multiplex and Shopping Malls</span></h2>
              <p>Multiplexes and shopping malls are the luxurious and leisure way to get out of the busy and hectic life. But in recent years...</p>
              <div className="more">
                <button className='contact_btn read_more_btn'><Link to="/multiplex" className='read-more'><span>Read More</span></Link></button>
              </div>
            </div>
          </div>
          <div className="industries-image">
            <img src={industry4} alt="" />
            <div className="details">
              <h2><span>Industrial Companies</span></h2>
              <p>Industrial Fires and Explosions cost companies and governments billions of rupees every year apart from the loss of life, which can’t be...</p>
              <div className="more">
                <button className='contact_btn read_more_btn'><Link to="/industrial-companies" className='read-more'><span>Read More</span></Link></button>
              </div>
            </div>
          </div>

          <div className="industries-image">
            <img src={industry5} alt="" />
            <div className="details">
              <h2><span>Hotels and Restaurants</span></h2>
              <p>Understanding fire hazards and installing the proper fire safety equipment to limit the fire risks is an important aspect to keep hotels safe from fire...</p>
              <div className="more">
                <button className='contact_btn read_more_btn'><Link to="/hotels" className='read-more'><span>Read More</span></Link></button>
              </div>
            </div>
          </div>

          <div className="industries-image">
            <img src={industry6} alt="" />
            <div className="details">
              <h2><span>Schools and Hospitals</span></h2>
              <p>Healthcare facilities such as hospitals and nursing homes normally see a continuous flow of people. Hospitals are not only required to take care of...</p>
              <div className="more">
                <button className='contact_btn read_more_btn'><Link to="/school" className='read-more'><span>Read More</span></Link></button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}
