import React, { useState } from 'react';
import MenuItemsProducts  from './MenuItemsProducts';
import './Dropdown.css';
import { Link } from 'react-router-dom';

function DropdownProducts() {
  const [click_products, setClickProducts] = useState(false);

  const handleClick_products = () => setClickProducts(!click_products);

  return (
    <>
      <ul
        onClick_products={handleClick_products}
        className={click_products ? 'dropdown-menu clicked' : 'dropdown-menu'}
      >
        {MenuItemsProducts.map((item, index) => {
          return (
            <li key={index}>
              <Link
                className={item.cName}
                to={item.path}
                onClick_products={() => setClickProducts(false)}
              >
                {item.title}
              </Link>
            </li>
          );
        })}
      </ul>
    </>
  );
}

export default DropdownProducts;
