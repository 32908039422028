import React from 'react';
import './FireHazard.css';
import imges1 from '../../../images/Consultancy.jpg';
import Footer from '../../Footer';
import { Link } from 'react-router-dom';

const Consultancy = () => {
    return (
        <>
            <div className="service_main">
                <section class="services_detail">
                    <div class="about_us">
                        <div class="row2">
                            <div class="about_col">
                                <img src={imges1} alt="about us" />
                            </div>
                            <div class="about_col detail_section">

                                <h1>Consultancy & Training</h1>

                                <p class="mentor_details">As a leading Fire Protection firm, we help organizations to manage their Fire and Safety Risks. Our Fire Safety Consultants are having years of industry experience to provide you the best-suited solutions</p>

                                <p class="mentor_details">Our solutions help you to meet legal obligations, ISO 45001 requirements, and industry best practices.</p>
                            </div>
                        </div>
                        <div className="product-enquire-btn">
                            <button className='enqire_more_btn'><Link to="/contact-us" className='enquire-more'>Enquire</Link></button>
                        </div>
                    </div>
                </section>
                <Footer />
            </div>
        </>
    )
}

export default Consultancy;