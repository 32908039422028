import React from 'react';
import './MainPage.css';
import { Link } from 'react-router-dom';
import smoke from '../../../images/smoke.mp4';
import Footer from '../../Footer';
const MainPage = () => {
  return (
    <>
      <div id="container">
        <div className="service">
          <video autoPlay muted loop>
            <source src={smoke} type="video/mp4" />
          </video>
          <h1>
            <span>O</span>
            <span>U</span>
            <span>R</span>
            &nbsp;
            <span>S</span>
            <span>E</span>
            <span>R</span>
            <span>V</span>
            <span>I</span>
            <span>C</span>
            <span>E</span>
            <span>S</span>
          </h1>
          <p>Professional, world-class services to help you understand and fulfil your safety needs.</p>
        </div>
      </div>

      {/* ######### Card Section ######## */}
      <section className="cards_section">

        <div className="card card1">
          {/* <img src={service_img1} alt="" className="class-img" /> */}
          <div className="card_content">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <h1 className="title">Fire Hazard Analysis</h1>
            <p className="info">A fire hazard analysis (FHA) is a method of evaluating the hazards present ...</p>
            <Link to='/fire-hazard' className='read_more' >
              Read More..
            </Link>
          </div>
        </div>
        <div className="card card2">
          <div className="card_content">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <h1 className="title">Fire Risk Assessments</h1>
            <p className="info"> Fire risk assessments are typically used to validate performance-based solutions...</p>
            <Link to='/fire-risk-assessments' className='read_more' >
              Read More..
            </Link>
          </div>
        </div>
        <div className="card card5">
          {/* <img src={service_img1} alt="" className="class-img" /> */}
          <div className="card_content">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <h1 className="title">System Design & Engineering</h1>
            <p className="info">The design of any fire-protection...</p>
            <Link to='/system-design-and-engineering' className='read_more' >
              Read More..
            </Link>
          </div>
        </div>

        <div className="card card7">
          <div className="card_content">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <h1 className="title">Annual Maintenance Contract</h1>
            <p className="info">Inspection and maintenance services ensure that your fire protection equipment ...</p>
            <Link to='/annual-maintain-contract' className='read_more' >
              Read More..
            </Link>
          </div>
        </div>

        <div className="card card4">
          <div className="card_content">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <h1 className="title">Equipment Procurement & Supply</h1>
            <p className="info">Procurement is the process ...</p>
            <Link to='/equipment-procurement-supply' className='read_more'>
              Read More..
            </Link>
          </div>
        </div>

        <div className="card card6">
          <div className="card_content">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <h1 className="title">Installation & Commission</h1>
            <p className="info">Fire Alarm System Commissioning is defined in BS5839-1 as the process which involves ...</p>
            <Link to='/installation-and-commissioning' className='read_more' >
              Read More..
            </Link>
          </div>
        </div>

        <div className="card card3">
          <div className="card_content">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <h1 className="title">Consultancy & Training</h1>
            <p className="info">As a leading Fire Protection firm, we help organizations to manage their Fire and ...</p>
            <Link to='/consultancy-training' className='read_more' >
              Read More..
            </Link>
          </div>
        </div>

      </section>







      {/* <section class="features" id="features">


    <div class="box-container">

        <div class="box">
            <img src={service_img1} alt="" />
            <h3>Fire Hazard Analysis</h3>
            <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Deserunt, earum!</p>
            <a href="#" class="btn">read more</a>
        </div>

        <div class="box">
            <img src={service_img1} alt="" />
            <h3>Fire Risk Assessments</h3>
            <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Deserunt, earum!</p>
            <a href="#" class="btn">read more</a>
        </div>

        <div class="box">
            <img src={service_img1} alt="" />
            <h3>Consultancy and Training</h3>
            <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Deserunt, earum!</p>
            <a href="#" class="btn">read more</a>
        </div>

    </div>

</section> */}
      <Footer />
    </>
  )
}

export default MainPage


// Our services size changes...
// Qoutes add..
// red black white..theme