import React from 'react'
import './product_main.css';
import img1 from '../../../images/cctv.jpg';
import Footer from '../../Footer';
import { Link } from 'react-router-dom';
const CctvCamera = () => {
  return (
    <>
      <div className="service_main">
        <section className="services_detail">
          <div className="about_us">
            <div className="row2">
              <div className="about_col">
                <img src={img1} alt="about us" />
              </div>
              <div className="about_col detail_section">

                <h1>CCTV Camera</h1>

                <p className="mentor_details">Closed-circuit television (CCTV). The use of video cameras to relay a signal to a particular location, on a restricted collection of monitors, is known as video surveillance. The signal is not provided free, including broadcast television, and it may use point-to-point (P2P), point-to-multipoint (P2MP), or mesh wired or wireless connections. While almost all video cameras meet this description, the term is most commonly applied to those used for surveillance in areas where protection is required, such as banks, shops, and other public places.</p>

                <h2>Accessories</h2>
                <ul className="mentor_details">
                  <li className='points'>Camera</li>
                  <li className='points'>DVR</li>
                  <li className='points'>PC Based DVR Card</li>
                  <li className='points'>Non PC Based DVR ( Stand Alone )</li>
                </ul>
              </div>
            </div>
            <div className="product-enquire-btn">
              <button className='enqire_more_btn'><Link to="/contact-us" className='enquire-more'>Enquire</Link></button>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </>
  )
}

export default CctvCamera
