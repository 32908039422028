import React from 'react'
import Footer from '../Footer';
import './Services/MainPage.css';
import './Home.css'
import smoke from '../../images/smoke.mp4';
import logo13 from '../../images/logo4.jpg';
import logo2 from '../../images/logo5.jpg';
import logo3 from '../../images/logo6.png';
import logo4 from '../../images/logo7.jpg';
import logo5 from '../../images/logo8.png';
import logo6 from '../../images/logo9.png';
import logo7 from '../../images/logo10.png';
import logo8 from '../../images/logo11.jpg';
import logo9 from '../../images/logo12.jpg';
import logo10 from '../../images/crompton.png';
import logo11 from '../../images/logo13.png';
import logo12 from '../../images/gail2.png';
import logo1 from '../../images/voltas.png';
import logoJost from '../../images/jost.png';
import logo15 from '../../images/spd.jpg';
import logo16 from '../../images/pasa.png';

import './Client.css';

const Clients = () => {
  return (
    <>
      <div id="container">
        <div className="service">
          <video autoPlay muted loop>
            <source src={smoke} type="video/mp4" />
          </video>
          <h1>
            <span>C</span>
            <span>L</span>
            <span>I</span>
            <span>E</span>
            <span>N</span>
            <span>T</span>
            <span>S</span>

          </h1>
          <p>Trusted by prestigious brands</p>
        </div>
      </div>
      <div className='clients-section'>
        <div className="clients-row">
          <div className="client">
            <img src={logo6} alt="" />
          </div>
          <div className="client">
            <img src={logo12} alt="" />
          </div>
          <div className="client">
            <img src={logo2} alt="" />
          </div>
          <div className="client">
            <img src={logo3} alt="" />
          </div>
          <div className="client">
            <img src={logo5} alt="" />
          </div>
          <div className="client">
            <img src={logo4} alt="" />
          </div>
          <div className="client">
            <img src={logo7} alt="" />
          </div>
          <div className="client">
            <img src={logo1} alt="" />
          </div>
          <div className="client">
            <img src={logo9} alt="" />
          </div>
          <div className="client">
            <img src={logo10} alt="" />
          </div>
          <div className="client">
            <img src={logo8} alt="" />
          </div>
          <div className="client">
            <img src={logo11} alt="" />
          </div>
          <div className="client">
            <img src={logo13} alt="" />
          </div>
          <div className="client">
            <img src={logoJost} alt="" />
          </div>
          <div className="client">
            <img src={logo15} alt="" />
          </div>
          <div className="client">
            <img src={logo16} alt="" />
          </div>
        </div>
        <div className="comment-line">
          <p>* The above corporate logos are property of the respective companies and used for representative purpose only</p>
        </div>
      </div>

      {/* ####################################### Testimonial Section ########################3 */}
      <section className="testimonial_section">
        <h1 className="testimonial_heading">Testimonials</h1>
        <div className="testimonial">
          <div className="cards">
            <div className="layer"></div>
            <div className="contents">
              <p>" It was a very wonderful and positive experience to work with Abhi fire safety services LLP led by very
                enthusiastic young professional Mr. Abhiraj. The way he assures you is really positive. I had only 2 or
                3 meetings with Mr. Abhiraj and I am very impressed with his commitment towards his job. The
                service we got from Abhi Fire was excellent from every perspective. Best wishes to Abhi fire and his
                team for future projects."</p>
              <div className="image">
              </div>
              <div className="details">
                <h2>Anu Saurav <br /><span>Senior officer (Fire&amp;Safety)<br />GAIL (India) Limited</span></h2>
              </div>
            </div>
          </div>

          <div className="cards">
            <div className="layer"></div>
            <div className="contents">
              <p>" We appreciate Abhi Fire for their excellent Camera surveillance system and fire extinguisher in our
                building. Their attention to detail, great communication skills, and experienced employees made the
                experience even better. We are satisfied with their products and services as their products have
                been working satisfactorily. Thanks to their company and the excellent service provided by them. "</p>
              <div className="image customer2">
              </div>
              <div className="details">
                <h2>CA Amit Kumar Agrawal<br /><span>FCA <br />AGRAWAL A K &amp; ASSOCIATES</span></h2>
              </div>
            </div>
          </div>

          <div className="cards">
            <div className="layer"></div>
            <div className="contents">
              <p>" We would like to inform you that the work awarded to you towards supply of Flexible hose, shifting /
                fixing of sprinkler above false ceiling and testing the sprinklers at Proposed Zonal Office, Sai
                Corporate Park, Patna was completed by you within the timeframe allotted to you.
                <br />
                <br />
                The work executed was satisfactory and as per the standard of the Bank. "</p>
              <div className="image customer4">
              </div>
              <div className="details other_changes">
                <h2>Raja Choudhary <br /><span>Manager(FIMD)<br />
                  Patna Zonal Office, IDBI Bank Ltd.</span></h2>
              </div>
            </div>
          </div>

          <div className="cards">
            <div className="layer"></div>
            <div className="contents">
              <p>" My first interaction with Mr. Abhiraj of M/s ABHI SAFETY FIRE happened in year 2021 pre/post covid 2nd wave and it was the time that the company had just initiated their business journey. In this due course of year and half I have received an extended support from M/s ABHI FIRE SAFETY in sourcing prospective.
                I pray to the almighty to grace M/s ABHI Safety fire to achieve all the honuour in their business journey "</p>
              <div className="image customer3">
                {/* ,respect and forthcoming successes */}
              </div>
              <div className="details">
                <h2>Madan Mohan Mishra <br /><span>Deputy Manager - Sourcing<br />
                  Moglix</span></h2>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  )
}

export default Clients
