import React from 'react'
import './product_main.css';
import img1 from '../../../images/dust_sussepsion.jpg';
import Footer from '../../Footer';
import { Link } from 'react-router-dom';
const DustSuppression = () => {
    return (
        <>
            <div className="service_main">
                <section className="services_detail">
                    <div className="about_us">
                        <div className="row2">
                            <div className="about_col">
                                <img src={img1} alt="about us" />
                            </div>
                            <div className="about_col detail_section">

                                <h1>Dust Suppression System</h1>

                                <p className="mentor_details">The Sprinkler System for Dust Suppression is basically designed to prevent liftoff of Dust from stockpiles and roads. Water Spray over the Stockpile area is produced by the sufficient number of sprinklers suitably installed in a ring manner to cover the entire projected area of the Stock Pile/Roads. The Sprinklers are swiveling type with the provision of change in the swiveling angle as well as the angle of the nozzle from the horizontal. The sprinklers break up the water into small droplets simulating natural rainfall. Each Sprinkler is controlled by a flow activation system having the facility to operate the sprinkler in a pre-determined duration. The water sprayed on the Stockpile will wet the material and prevent lift-off of Dust due to wind etc.</p>

                                <p className="mentor_details">For a complex material handling system, there is a need of utilizing a combination of the above system. Since our Organization manufactures all kinds of Dust Suppression Systems, we are in a unique position to offer a comprehensive solution to the Dust Suppression needs of Material Handling System and match and optimize the dust control requirements and process needs.</p>

                                <h2>Application Field</h2>
                                <p className="mentor_details">Stockyard, Hall road, and a berth in the seaport including open store/Covered store and PG Test is not applicable for the system due to open execution.</p>

                            </div>
                        </div>
                        <div className="product-enquire-btn">
                <button className='enqire_more_btn'><Link to="/contact-us" className='enquire-more'>Enquire</Link></button>
              </div>
                    </div>
                </section>
                <Footer />
            </div>
        </>
    )
}

export default DustSuppression
