import React from 'react';
import './CommercialBuildings.css';
// import imges1 from '../../../images/system-design-engineering.jpeg';
import industry1 from '../../../images/05.jpg';
import Footer from '../../Footer';
import { Link } from 'react-router-dom';

const CommercialBuildings = () => {
    return (
        <>
            <div className="service_main">
                <section class="services_detail">
                    <div class="about_us">
                        <div class="row2">
                            <div class="about_col">
                                <img src={industry1} alt="about us" />
                            </div>
                            <div class="about_col detail_section">

                                <h1>Commercial and Residence Buildings</h1>

                                <p class="mentor_details">Buildings are growing vertically taller as a result of increased urbanisation and space constraints, which makes fire safety and rescue more challenging. In a few incidents of residential fire outbreaks in large cities, it was discovered that the buildings lacked fire safety equipment and detection systems.</p>

                                <p class="mentor_details">Unless otherwise stated in these Bye-Laws, buildings shall be planned, designed, and built to guarantee fire safety in accordance with part IV Fire Protection of the National Building Code (NBC) of India.</p>

                                <p class="mentor_details">The installation of fire suppression systems and fire alarms is a basic requirement for every developer as fire safety is a key factor in both the design and maintenance of buildings. Employees, tenants, and the workforce get familiar with evacuation procedures through regular fire drills. </p>
                            </div>
                        </div>
                        <div className="more_details">
                            <p>Therefore, in order to prevent any accidents, developers are now concentrating on lining up the highest fire safety precautions. It offers the chance to totally eliminate the dangers associated with fire outbreaks. In order to keep your property safe from any potential danger, fire safety in commercial buildings is a continual process that necessitates a number of proactive activities.
                                Abhi Fire Safety fulfils all the necessary requirements for fire safety in Commercial and Residential Buildings. The major products and services include:
                            </p>

                            <ul>
                                <li>Fire Extinguishers</li>
                                <li>Fire Alarms</li>
                                <li>Fire Drills</li>
                            </ul>

                        </div>
                        <div className="product-enquire-btn">
                            <button className='enqire_more_btn'><Link to="/contact-us" className='enquire-more'>Enquire</Link></button>
                        </div>
                    </div>

                </section>
                <Footer />
            </div>
        </>
    )
}

export default CommercialBuildings;