import React from 'react'
import './product_main.css';
import img1 from '../../../images/accessControl.jpg';
import Footer from '../../Footer';
import { Link } from 'react-router-dom';
const AccessControl = () => {
  return (
    <>
      <div className="service_main">
        <section className="services_detail">
          <div className="about_us">
            <div className="row2">
              <div className="about_col">
                <img src={img1} alt="about us" />
              </div>
              <div className="about_col detail_section">

                <h1>Access Control System</h1>

                <p className="mentor_details">Access control systems are the electronic systems that are designed to control through a network and they should have an access to a network. The Access Control System recognises and authorises a person’s entry into the premise, providing full protection and ensuring the system’s security.</p>

                <h2>Accessories</h2>
                <ul className="mentor_details">
                  <li className='points'>Main controller</li>
                  <li className='points'>Two door controller</li>
                  <li className='points'>Four door controller</li>
                  <li className='points'>Multi door controller</li>
                  <li className='points'>Reader</li>
                  <li className='points'>EM Lock</li>
                  <li className='points'>Cables & Power Supply</li>
                  <li className='points'>Access Cards</li>
                </ul>
              </div>
            </div>
            <div className="product-enquire-btn">
              <button className='enqire_more_btn'><Link to="/contact-us" className='enquire-more'>Enquire</Link></button>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </>
  )
}

export default AccessControl
