import React from 'react';
import './AboutUs.css';
import img1 from '../../images/Quality_Icon.png';
import img2 from '../../images/People_First_Icon.PNG';
import img3 from '../../images/Accountability_Icon.PNG';
import img4 from '../../images/Adaptability_Icon.PNG';
import img5 from '../../images/Ethics_Icon.PNG';
import img from '../../images/target.jpg';
import './Services/MainPage.css';
import smoke from '../../images/smoke.mp4';
import mem1 from '../../images/member1.png';
import mem2 from '../../images/member2.png';
import mem3 from '../../images/member3.png';
import mem4 from '../../images/member4.png';
import mem5 from '../../images/member5.png';
import mem6 from '../../images/member6.png';
import Fire from '../../images/fire.webp';
import IIM_Logo from '../../images/samsung.png';
import NIT_Jalandhar from '../../images/Logo_of_NIT_Jalandhar.png';
import Banasthali from '../../images/Banasthali.png';
import Capgemini from '../../images/capgemini.jpg';
import NIFFT from '../../images/NIFFT_ranchi.png';
import iso_certificate from '../../images/iso_certificate.JPG'
import startup_certificate from '../../images/startup_certificate.jpg';
import Footer from '../Footer';

function AboutUs() {
    return (
        <>
            <div id="container">
                <div className="service">
                    <video autoPlay muted loop>
                        <source src={smoke} type="video/mp4" />
                    </video>
                    <h1>
                        <span>A</span>
                        <span>B</span>
                        <span>O</span>
                        <span>U</span>
                        <span>T</span>
                        &nbsp;
                        <span>U</span>
                        <span>S</span>
                    </h1>
                    <p>Set ablaze your worries on Safety</p>
                </div>
            </div>
            <div className="about-us-section">
                <div className="about-intro">
                    <div className="content-border">
                        <h1 className="about-heading">Abhi Fire Safety</h1>
                        <p className='about-content' >Abhi Fire Safety is one of the fastest growing enterprises in the field of "Fire Safety and Prevention through specialized engineering and expertise.”</p>
                        <p className='about-content' >Professionals with an experience of <span>25+ years</span> and alumni from <span>IITs, IIMs and NIFFT</span> have come together with a common vision to achieve Trust and Leadership in the Fire Safety Domain.</p>
                        <p className='about-content' >Because of serious commitment towards Quality, Ethics and Client Support, Abhi Fire Safety has already been accredited with certifications like <span>ISO 9001:2000</span> and Startup India recognition and has already served humongous organizations like Indian Oil, Filtrum, Linde, Fleetguard etc.</p>
                    </div>
                </div>

                <div className="about-intro">
                    <div className="content-border">
                        <h1 className="vision-heading">Vision and Mission</h1>
                        <p className="about-content">
                            Achieve Trust and Leadership in Fire
                            Safety Domain through development
                            of innovative world class products,
                            solutions and services that minimize
                            fire hazards and fulfil customer needs
                            beyond expectations.
                        </p>
                        <img src={img} alt="" />
                    </div>
                </div>
            </div>


            <div className="core-container">
                <h1 className="core_header">Core Values</h1>
                <div className="core-row">
                    <div className="core-image">
                        <img src={img1} alt="" />
                        <div className="core-details">
                            <h2><span>Quality Focus</span></h2>
                            <p>We strive for the best
                                quality. Utmost
                                attention is paid to
                                attain quality at each
                                step of the process</p>
                        </div>
                    </div>

                    <div className="core-image">
                        <img src={img2} alt="" />
                        <div className="core-details">
                            <h2><span>People First</span></h2>
                            <p>We keep people at
                                the forefront in every
                                decision, be it our
                                employees, clients or
                                vendors.</p>
                        </div>
                    </div>

                    <div className="core-image">
                        <img src={img3} alt="" />
                        <div className="core-details">
                            <h2><span>Accountability</span></h2>
                            <p>We take accountability
                                in delivering the best in
                                class products and
                                services on time with
                                paramount satisfaction.</p>
                        </div>
                    </div>
                    <div className="core-image">
                        <img src={img4} alt="" />
                        <div className="core-details">
                            <h2><span>Adaptability</span></h2>
                            <p>We keep upgrading our
                                processes and practices
                                with the changing
                                technology and
                                environment.</p>
                        </div>
                    </div>
                    <div className="core-image">
                        <img src={img5} alt="" />
                        <div className="core-details">
                            <h2><span>Ethics</span></h2>
                            <p>We hold ourselves to
                                be fair and honest in
                                the conduct of our
                                business with
                                highest ethical
                                standards.</p>
                        </div>
                    </div>
                </div>
            </div>
            <section className="brochure_section">
                <p>Want to Know More About us? </p>
                <p>Download the brouchure from the link below: </p>
                <a href="https://drive.google.com/file/d/17kwlGVZ7ZBrZaKBVdkzi66cp6QJe9qkI/view" target="_blank" rel="noreferrer" className="download-btn">Download</a>
            </section>







            <div class="team_section_container">
                <div class="team_container">
                    <div class="text">Meet Our Team</div>
                </div>
                <div class="team_row">

                    <div class="profile-card_section">
                        <div class="profile-content_card">
                            <div class="profile-image_card">
                                <img src={mem1} alt="first user" />
                            </div>
                            <div class="member_desc">
                                <h2>Navin Kumar</h2>
                                <p>25+ Years of experience<br></br>Fire & Safety</p>
                            </div>
                            <div className="company_images">
                                <img className="images_logos" src={Fire} alt="" />
                            </div>
                        </div>
                    </div>

                    <div class="profile-card_section">
                        <div class="profile-content_card">
                            <div class="profile-image_card">
                                <img src={mem2} alt="first user" />
                            </div>
                            <div class="member_desc">
                                <h2>Heenu Gupta</h2>
                                <p>IIM Lucknow <br></br>NIT Jalandhar Ex-Samsung</p>
                            </div>
                            <div className="company_images">
                                <img className="images_logos" src={IIM_Logo} alt="" />
                                <img className="images_logos" src={NIT_Jalandhar} alt="" />
                            </div>
                        </div>
                    </div>

                    <div class="profile-card_section">
                        <div class="profile-content_card">
                            <div class="profile-image_card">
                                <img src={mem3} alt="first user" />
                            </div>
                            <div class="member_desc">
                                <h2>Abhishek Patel</h2>
                                <p>NIT Jalandhar <br></br>Capgemini Limited</p>
                            </div>
                            <div className="company_images">
                                <img className="images_logos" src={NIT_Jalandhar} alt="" />
                                <img className="images_logos" src={Capgemini} alt="" />
                            </div>
                        </div>
                    </div>

                    <div class="profile-card_section">
                        <div class="profile-content_card">
                            <div class="profile-image_card">
                                <img src={mem4} alt="first user" />
                            </div>
                            <div class="member_desc">
                                <h2>Abhiraj Patel</h2>
                                <p>B.Tech <br></br>NIFFT Ranchi</p>
                            </div>
                            <div className="company_images">
                                <img className="images_logos" src={NIFFT} alt="" />
                            </div>
                        </div>
                    </div>

                    <div class="profile-card_section">
                        <div class="profile-content_card">
                            <div class="profile-image_card">
                                <img src={mem5} alt="first user" />
                            </div>
                            <div class="member_desc">
                                <h2>Arun Shankar Gupta</h2>
                                <p>B.Tech <br></br>NIT Jalandhar</p>
                            </div>
                            <div className="company_images">
                                <img className="images_logos" src={NIT_Jalandhar} alt="" />
                            </div>
                        </div>
                    </div>

                    <div class="profile-card_section">
                        <div class="profile-content_card">
                            <div class="profile-image_card">
                                <img src={mem6} alt="first user" />
                            </div>
                            <div class="member_desc">
                                <h2>Jimmy Kumari</h2>
                                <p>MBA<br></br>Banasthali University</p>
                            </div>
                            <div className="company_images">
                                <img className="images_logos" src={Banasthali} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <section className="certifications">
                <h1 className="certificate_heading">Certifications</h1>
                <div className="certificate_section">
                    <div className="iso_certificate">
                        <h1>Start-up Certification</h1>
                        <p>AFS is recognized as a startup by the Department for Promotion of Industry and Internal Trade in "Safety Industry and Personal Security" sector.</p>
                    </div>
                    <div className="startup_certificate">
                        <img src={startup_certificate} alt="" />
                    </div>
                </div>

                <div className="certificate_section section_two">
                    <div className="startup_certificate">
                        <img src={iso_certificate} alt="" />
                    </div>
                    <div className="iso_certificate">
                        {/* <img src={iso_certificate} alt="" /> */}
                        <h1>ISO Certification</h1>
                        <p>AFS is certified by Otabu Certification Pvt. Ltd. as meeting the requirements of ISO 9001:2015 Quality Management System. Providing quality services to our clients is our utmost priority.</p>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default AboutUs;