import React from 'react';
import './Home.css';
import { Link } from 'react-router-dom';
import img1 from '../../images/Fire-alarm.jpg';
import img2 from '../../images/fire-fighting.jpg';
import img3 from '../../images/fire-sussepsion.jpg';
import img4 from '../../images/accessControl.jpg';
import logo1 from '../../images/logo4.jpg';
import logo2 from '../../images/logo5.jpg';
import logo3 from '../../images/logo6.png';
import logo4 from '../../images/logo7.jpg';
import logo5 from '../../images/logo8.png';
import logo6 from '../../images/logo9.png';
import logo7 from '../../images/logo10.png';
import logo8 from '../../images/jost.png';
import logo9 from '../../images/logo12.jpg';
import logo14 from '../../images/voltas.png';
import logo10 from '../../images/crompton.png';
// import CookieConsent from "react-cookie-consent";
import Footer from '../Footer';
export default function Home() {


  return (

    <>
      <div className="banner">
        <div className="slider">
          {/* <img src={img1} alt="" id="slider_img" /> */}
        </div>
        <div className="overlay">
          <div className="content">
            <p>Welcome to</p>
            <h1 className="text-white">ABHI FIRE SAFETY <span class="banner_changes">SERVICES LLP</span></h1>
            <ul class="companyGoals">
              <li><i class="fa fa-check-square-o"></i>25+ years of professional experience</li>
              <li><i class="fa fa-check-square-o"></i>Highly qualified team from IIT/IIM</li>
              <li><i class="fa fa-check-square-o"></i>Comprehensive Fire Safety Solutions</li>
              <li><i class="fa fa-check-square-o"></i>End-to-end Quality Service</li>
            </ul>
            <Link to="./contact-us" className="Quote_btn text-white"> Enquire </Link>

          </div>
        </div>
      </div>

      <div className="client-slider">
        <h1 className="clients_heading">Our Clients</h1>
        <div className="slide-track">
          <div className="client-slide">
            <img src={logo1} alt="" />
          </div>
          <div className="client-slide">
            <img src={logo2} alt="" />
          </div>
          <div className="client-slide">
            <img src={logo3} alt="" />
          </div>
          <div className="client-slide">
            <img src={logo4} alt="" />
          </div>
          <div className="client-slide">
            <img src={logo5} alt="" />
          </div>
          <div className="client-slide">
            <img src={logo6} alt="" />
          </div>
          <div className="client-slide">
            <img src={logo7} alt="" />
          </div>
          <div className="client-slide">
            <img src={logo14} alt="" />
          </div>
          <div className="client-slide">
            <img src={logo8} alt="" />
          </div>
          <div className="client-slide">
            <img src={logo9} alt="" />
          </div>
          <div className="client-slide">
            <img src={logo10} alt="" />
          </div>
          <div className="client-slide">
            <img src={logo2} alt="" />
          </div>
          <div className="client-slide">
            <img src={logo3} alt="" />
          </div>
          <div className="client-slide">
            <img src={logo4} alt="" />
          </div>
          <div className="client-slide">
            <img src={logo5} alt="" />
          </div>
          <div className="client-slide">
            <img src={logo14} alt="" />
          </div>
        </div>
      </div>

      {/* ########################################## Service Section ################################# */}
      <div className="our_services">
        <h1 className="testimonial_heading">Our Services</h1>
        <div className="service_cards_section">
          <div className="service_card card1">
            <div className="service_card_content">
              <h2 className="service_card_title">Fire Hazard Analysis</h2>
              <div className="service_body">A fire hazard analysis (FHA) is a method of evaluating the hazards present ...</div>
              <Link to="/fire-hazard" exact className="service_button">Learn More...</Link>
            </div>
          </div>
          <div className="service_card card2">
            <div className="service_card_content">
              <h2 className="service_card_title">Fire Risk Assessments</h2>
              <div className="service_body">Fire risk assessments are typically used to validate performance-based solutions...</div>
              <Link to="/fire-risk-assessments" exact className="service_button">Learn More...</Link>
            </div>
          </div>
          <div className="service_card card30">
            <div className="service_card_content">
              <h2 className="service_card_title">System Design & Engg.</h2>
              <div className="service_body">The design of any fire-protection system is an exact science that takes ...</div>
              <Link to="/system-design-and-engineering" exact className="service_button">Learn More...</Link>
            </div>
          </div>

          <div className="service_card card40">
            <div className="service_card_content">
              <h2 className="service_card_title">Annual Maintenance</h2>
              <div className="service_body">Inspection and maintenance services ensure that your fire protection equipment ...</div>
              <Link to="/annual-maintain-contract" exact className="service_button">Learn More...</Link>
            </div>
          </div>
        </div>
        <div className="learn_button">
          <Link to="/services" exact className="Learn_more_button">View More...</Link>
        </div>
      </div>



      {/* ###################################################### Product Section ####################### */}

      <div className="products-section">
        <h1 className="testimonial_heading products_heading">Our Products</h1>
        <div className="products">
          <div className="product-card">
            <img src={img1} alt="" />
            <div className="product-details">
              <h2><span>Fire Alarm <br />Systems</span></h2>
              <p>Fire Alarm Systems must be installed in every building to alert people to the presence of a fire using alarms or manual call points.</p>
            </div>
          </div>

          <div className="product-card">
            <img src={img2} alt="" />
            <div className="product-details">
              <h2><span>Fire Fighting Systems</span></h2>
              <p>Automatic and manual firefighting devices should be used to protect ourselves and our property from fire.AFS  is a company that specializes...</p>
            </div>
          </div>

          <div className="product-card">
            <img src={img3} alt="" />
            <div className="product-details">
              <h2><span>Fire Suppression Systems</span></h2>
              <p>Fire Suppression Systems are detection and security systems that can be installed in server rooms, phone rooms, archives, and other high-value areas.</p>
            </div>
          </div>
          <div className="product-card">
            <img src={img4} alt="" />
            <div className="product-details">
              <h2><span>Dust Suppression System</span></h2>
              <p>The Sprinkler System for Dust Suppression is basically designed to prevent liftoff of Dust from stockpiles and roads. Water Spray over the Stockpile area...</p>
            </div>
          </div>
        </div>
        <div className="learn_button">
          <Link to="/products" exact className="Learn_more_button">View More...</Link>
        </div>
      </div>


      {/* ####################################### Testimonial Section ########################3 */}
      <section className="testimonial_section">
        <h1 className="testimonial_heading">Testimonials</h1>
        <div className="testimonial">
          <div className="cards">
            <div className="layer"></div>
            <div className="contents">
              <p>" It was a very wonderful and positive experience to work with Abhi fire safety services LLP led by very
                enthusiastic young professional Mr. Abhiraj. The way he assures you is really positive. I had only 2 or
                3 meetings with Mr. Abhiraj and I am very impressed with his commitment towards his job. The
                service we got from Abhi Fire was excellent from every perspective. Best wishes to Abhi fire and his
                team for future projects."</p>
              <div className="image">
              </div>
              <div className="details">
                <h2>Anu Saurav <br /><span>Senior officer (Fire&amp;Safety)<br />GAIL (India) Limited</span></h2>
              </div>
            </div>
          </div>

          <div className="cards">
            <div className="layer"></div>
            <div className="contents">
              <p>" We appreciate Abhi Fire for their excellent Camera surveillance system and fire extinguisher in our
                building. Their attention to detail, great communication skills, and experienced employees made the
                experience even better. We are satisfied with their products and services as their products have
                been working satisfactorily. Thanks to their company and the excellent service provided by them. "</p>
              <div className="image customer2">
              </div>
              <div className="details">
                <h2>CA Amit Kumar Agrawal<br /><span>FCA <br />AGRAWAL A K &amp; ASSOCIATES</span></h2>
              </div>
            </div>
          </div>

          {/* <div className="cards">
            <div className="layer"></div>
            <div className="contents">
              <p>" We would like to inform you that the work awarded to you towards supply of Flexible hose, shifting /
                fixing of sprinkler above false ceiling and testing the sprinklers at Proposed Zonal Office, Sai
                Corporate Park, Patna was completed by you within the timeframe allotted to you.
                <br />
                <br />
                The work executed was satisfactory and as per the standard of the Bank. "</p>
              <div className="image customer4">
              </div>
              <div className="details other_changes">
                <h2>Raja Choudhary <br /><span>Manager(FIMD)<br />
                  Patna Zonal Office, IDBI Bank Ltd.</span></h2>
              </div>
            </div>
          </div> */}

          <div className="cards">
            <div className="layer"></div>
            <div className="contents">
              <p>" My first interaction with Mr. Abhiraj of M/s ABHI SAFETY FIRE happened in year 2021 pre/post covid 2nd wave and it was the time that the company had just initiated their business journey. In this due course of year and half I have received an extended support from M/s ABHI FIRE SAFETY in sourcing prospective.
                I pray to the almighty to grace M/s ABHI Safety fire to achieve all the honuour in their business journey "</p>
              <div className="image customer3">
                {/* ,respect and forthcoming successes */}
              </div>
              <div className="details">
                <h2>Madan Mohan Mishra <br /><span>Deputy Manager - Sourcing<br />
                  Moglix</span></h2>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
