import React from 'react';
import './CommercialBuildings.css';
// import imges1 from '../../../images/system-design-engineering.jpeg';
import industry5 from '../../../images/10.jpg';
import Footer from '../../Footer';
import { Link } from 'react-router-dom';

const Hotels = () => {
    return (
        <>
            <div className="service_main">
                <section class="services_detail">
                    <div class="about_us">
                        <div class="row2">
                            <div class="about_col">
                                <img src={industry5} alt="about us" />
                            </div>
                            <div class="about_col detail_section">

                                <h1>Hotels and Restaurants</h1>

                                <p class="mentor_details">Understanding fire hazards and installing the proper fire safety equipment to limit the fire risks is an important aspect to keep hotels safe from fire. Whether you are managing a small restaurant, resort, or a large multi-story hotel complex, a critical aspect for customer satisfaction is not only service but also their safety.</p>

                                <p class="mentor_details">Cooking equipment in the kitchen is the leading cause of hotel fires. Other causes of hotel fires include storage rooms, gas banks, heating equipment, clothes dryers, lighting equipment, and arson. With the widespread use of vegetable oils having lower auto-ignition temperatures, kitchen environments are more at the risk of fire than ever before.</p>

                            </div>
                        </div>
                        <div className="more_details">
                            <p>Some measures to prevent the fire accidents in Hotels and Restaurants include:</p>

                            <ul>
                                <li>Having the right equipment in place is the first step of any fire safety plan. To ensure maximum protection, against hotel fires is to install the proper fire safety equipment and systems. </li>
                                <li>Ensuring that proper fire detection, and suppression systems are installed to make sure staff, guests and its occupants are safe from fire hazards. </li>
                                <li>The fire safety equipment and systems once commissioned, shall be maintained, repaired, and replaced as per the desired schedules and guidelines.</li>
                                <li>Hotel staff shall be properly trained and educated in fire safety protocols.</li>
                            </ul>

                        </div>
                        <div className="product-enquire-btn">
                            <button className='enqire_more_btn'><Link to="/contact-us" className='enquire-more'>Enquire</Link></button>
                        </div>
                    </div>
                </section>
                <Footer />
            </div>
        </>
    )
}

export default Hotels;