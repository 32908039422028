import React from 'react'
import './product_main.css';
import img1 from '../../../images/Fire-alarm.jpg';
import Footer from '../../Footer';
import { Link } from 'react-router-dom';
const FireAlarm = () => {
  return (
    <>
      <div className="service_main">
        <section className="services_detail">
          <div className="about_us">
            <div className="row2">
              <div className="about_col">
                <img src={img1} alt="about us" />
              </div>
              <div className="about_col detail_section">

                <h1>Fire Alarm Systems</h1>

                <p className="mentor_details">Fire Alarm Systems must be installed in every building to alert people to the presence of a fire using alarms or manual call points. Both types of fire alarm systems can be designed, supplied, installed, tested, and Commissioning by AFS.  AFS will provide an annual maintenance service to ensure that your Fire Alarm System is in good working condition through routine visits.</p>

                <ul className="mentor_details">
                  <li className='points'> ADDRESSABLE FIRE ALARM SYSTEM  </li>
                  <li className='points'>CONVENTIONAL FIRE ALARM PANNEL</li>
                </ul>
              </div>
            </div>
            <div className="product-enquire-btn">
              <button className='enqire_more_btn'><Link to="/contact-us" className='enquire-more'>Enquire</Link></button>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </>
  )
}

export default FireAlarm
