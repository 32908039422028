import React from 'react';
import './CommercialBuildings.css';
// import imges1 from '../../../images/system-design-engineering.jpeg';
import industry2 from '../../../images/06.jpg';
import Footer from '../../Footer';
import { Link } from 'react-router-dom';

const ServerRooms = () => {
    return (
        <>
            <div className="service_main">
                <section class="services_detail">
                    <div class="about_us">
                        <div class="row2">
                            <div class="about_col">
                                <img src={industry2} alt="about us" />
                            </div>
                            <div class="about_col detail_section">

                                <h1>Server Rooms</h1>

                                <p class="mentor_details">One of the most expensive and valuable parts of any organisation are the server rooms. Your company's IT infrastructure is essential given our growing reliance on technology. There's a considerable possibility you'd experience a significant loss of business continuity if something were to harm the technology in your server room. Unavoidable business and organisational interruptions occur, and while hardware can frequently be replaced in a timely manner, current data cannot. The corrosive compounds in smoke from a fire can do significant damage to network peripherals and IT servers even if the fire is quickly contained. High heat, short-term smoke exposure, and, of course, flame exposure can all harm delicate electronics.</p>

                                <p class="mentor_details">Wiring malfunctions, overheating, overloading, or short-circuiting can all quickly cause a fire in electric-heavy rooms such as a server room. Below are some of the prevention measures from fire safety point of view:</p>
                            </div>
                        </div>
                        <div className="more_details">

                            <ul>
                                <li><span>Maintain proper temperatures and humidity levels</span>- Server rooms should be cooled to between 20 and 25 °C, with a relative humidity of 40 to 60 %. These elements can lessen the likelihood of condensations, water ingress, and electrostatic discharge (static electricity).</li>
                                <li><span>Fire Assessment</span> - It's crucial to do a fire risk assessment, ideally having it done by an independent supplier or consultant who can offer an outside view and challenge assumptions.</li>
                                <li><span>Fire suppression system</span> - High power density rooms with the potential for fire and smoke damage must have a fire suppression system installed. Instead of using water, the majority of server room operators use a clean agent-based fire protection system.
                                    <br />Sprinkler system water leaks have the ability to do irreparable harm to systems and cause even more damage. Clean agent-based systems can put out a fire rapidly and with little harm to the surrounding environment or property. The clean agent is a gas, chemical, or mixture that is chosen to fit the room's surroundings, the equipment inside of it, and to adhere to rules and regulations.
                                </li>
                                <li><span>Routine maintenance and equipment inspections</span>-Any equipment or power cords that are broken must be replaced right away. As failing equipment tends to become less energy efficient and warmer as it approaches component breakdown and end-of-life, routine maintenance and temperature monitoring can spot possible system or component breakdowns. Power supplies and batteries are good examples of this which can be caught and identified during regular preventative maintenance of critical systems (power, cooling and IT).</li>
                            </ul>

                        </div>
                        <div className="product-enquire-btn">
                            <button className='enqire_more_btn'><Link to="/contact-us" className='enquire-more'>Enquire</Link></button>
                        </div>
                    </div>
                </section>
                <Footer />
            </div>
        </>
    )
}

export default ServerRooms;