import React, { useEffect } from 'react';

const VoiceglowWidget = () => {

  useEffect(() => {
    // Define the configuration object for Voiceglow
    window.VG_CONFIG = {
      ID: "1go34gvto",
      region: 'eu', // 'eu' or 'na' corresponding to Europe and North America
      render: 'popup', // popup or full-width
      stylesheets: [
        "https://storage.googleapis.com/voiceglow-cdn/vg_live_build/styles.css",
        // You can add more custom CSS stylesheets here
      ],
    }

    // Create the script element for Voiceglow
    const vgScript = document.createElement("script");
    vgScript.src = "https://storage.googleapis.com/voiceglow-cdn/vg_live_build/vg_bundle.js";
    vgScript.defer = true;
    document.body.appendChild(vgScript);

    // Cleanup function to remove the script when the component unmounts
    return () => {
      document.body.removeChild(vgScript);
    };
  }, []); // Empty dependency array to ensure this runs once on mount

  return 
  (
    <div style={{width: 0, height: 0}} id="VG_OVERLAY_CONTAINER">
      
    </div>
  ); // This component does not render anything
};

export default VoiceglowWidget;
