export const MenuItems = [
  {
      title: 'Fire Hazard Analysis',
      path: '/fire-hazard',
      cName: 'dropdown-link',        
  },
  {
      title: 'Fire Risk Assessments',
      path: '/fire-risk-assessments',
      cName: 'dropdown-link',        
  },
  {
      title: 'Consultancy & Training',
      path: '/consultancy-training',
      cName: 'dropdown-link',        
  },
  {
      title: 'System Design & Engineering',
      path: '/system-design-and-engineering',
      cName: 'dropdown-link',        
  },
  {
      title: 'Equipment Procurement & Supply',
      path: '/equipment-procurement-supply',
      cName: 'dropdown-link',        
  },
  {
      title: 'Installation & Commissioning',
      path: '/installation-and-commissioning',
      cName: 'dropdown-link',        
  },
  {
      title: 'Annual Maintenance Contract',
      path: '/annual-maintain-contract',
      cName: 'dropdown-link',        
  }
];