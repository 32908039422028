import React from 'react';
import './ContactUs.css';
import emailjs from 'emailjs-com';
import Footer from '../../Footer';


const PatnaOffice = () => {

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm(
      // 'service_ehs1o33',
      // 'template_xzhbhsf',
      // e.target,
      // '-T898Jed3jn6Bhtft'
      'service_contact_form',
      'template_exjur6h',
      e.target,
      'vb7Y17NKH-o6oeVNN'
    ).then(res => {
      alert("Message Sent Successfully");
      document.getElementById('myform').reset();
    }).catch(err => console.log(err));
  }

  return (
    <>

      <div className="contact_details_section">
        <h1>GET IN TOUCH</h1>
        <h1>Abhi Fire Safety Services LLP</h1>
        <p>PATNA Office Address</p>
        <div className="contact_detail">
          <div className="contact_phone">
            <ion-icon name="call-outline"></ion-icon>
            <p><a href="tel:+91 9341527027" >+91 9341527027</a></p>
          </div>
          <div className="contact_address">
            <ion-icon name="location-outline"></ion-icon>
            <p>Abhi House, 1st Floor, 90 Feet Road, Near Bhagawat Milan Mandir, Bhagawat Nagar, Patna, Bihar – 800026 </p>
          </div>
          <div className="contact_email">
            <ion-icon name="mail-outline"></ion-icon>
            <p><a href="mailto: info@abhifiresafety.com">info@abhifiresafety.com</a></p>
          </div>
        </div>
      </div>



      <section className="contact_form_area branch-page">
        <div className="contact_form_container">
          <div className="contact_map">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3598.48508301283!2d85.1553460143358!3d25.588792521982366!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39ed59a1c3b17b47%3A0x1770cf0e2f9e726c!2sAbhi%20Fire%20Safety%20Services%20LLP!5e0!3m2!1sen!2sin!4v1656609757634!5m2!1sen!2sin" title="Patna Office Address"></iframe>
          </div>
          <div className="contact_form">
            <h2>Contact Us</h2>
            <p>(Don't Worry! We won't spam you)</p>
            {/* ################# Contact us form row... ################### */}
            <div className="row100">
              {/* ############### Name Input field... ########### */}
              <form action="" onSubmit={sendEmail} id="myform">
                <div className="contact_col">
                  <div className="input_box">
                    <input type="text" name="name" required="required" placeholder="Name *" />
                    {/* <span className="input_text">Name <span className="required_imp">*</span></span> */}
                    <span className="input_line"></span>
                  </div>
                </div>

                {/* ############ Email input field ################### */}
                <div className="contact_col">
                  <div className="input_box">
                    <input type="email" name="email" placeholder="Email " />
                    {/* <span className="input_text">Email <span className="required_imp">*</span></span> */}
                    <span className="input_line"></span>
                  </div>
                </div>

                {/* ################## Phone input field ################ */}
                <div className="contact_col">
                  <div className="input_box">
                    <input type="phone" name="phone" required="required" placeholder="Phone Number *" />
                    {/* <span className="input_text">Phone Number <span className="required_imp">*</span></span> */}
                    <span className="input_line"></span>
                  </div>
                </div>

                {/* ################## organisation field...###############*/}
                <div className="contact_col">
                  <div className="input_box">
                    <input type="text" name="organisation" placeholder='Organisation' />
                    {/* <span className="input_text">Organisation</span> */}
                    <span className="input_line"></span>
                  </div>
                </div>

                {/* ############## text Area field..###################3 */}
                <div className="contact_col">
                  <div className="input_box contact_text_area">
                    <textarea placeholder="Type your message here..." name="message"></textarea>
                    {/* <span className="input_text">Type your Message Here...</span> */}
                    <span className="input_line"></span>
                  </div>
                </div>

                <div className="contact_col">
                  <div className="input_box">
                    <input type="submit" value="Send" />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>



















      <Footer />
    </>
  )
}

export default PatnaOffice;
