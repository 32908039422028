import React from 'react';
import './FireHazard.css';
import imges1 from '../../../images/e-procurement-Ans-Supply.png';
import Footer from '../../Footer';
import { Link } from 'react-router-dom';

const EquipmentProcurement = () => {
    return (
        <>
            <div className="service_main">
                <section class="services_detail">
                    <div class="about_us">
                        <div class="row2">
                            <div class="about_col">
                                <img src={imges1} alt="about us" />
                            </div>
                            <div class="about_col detail_section">

                                <h1>Equipment Procurement & Supply</h1>

                                <p class="mentor_details">Procurement is the process of finding and agreeing to terms, and acquiring goods, services, Direct procurement, which is the focus in supply chain management, directly affects the Purchasing refers to the major function of an organization that is responsible for the acquisition of required materials, services, and equipment.
                                </p>
                            </div>
                        </div>
                        <div className="product-enquire-btn">
                            <button className='enqire_more_btn'><Link to="/contact-us" className='enquire-more'>Enquire</Link></button>
                        </div>
                    </div>
                </section>
                <Footer />
            </div>
        </>
    )
}

export default EquipmentProcurement;