import React from 'react';
import './CommercialBuildings.css';
// import imges1 from '../../../images/system-design-engineering.jpeg';
import industry3 from '../../../images/07.jpg';
import Footer from '../../Footer';
import { Link } from 'react-router-dom';

const Multiplex = () => {
    return (
        <>
            <div className="service_main">
                <section class="services_detail">
                    <div class="about_us">
                        <div class="row2">
                            <div class="about_col">
                                <img src={industry3} alt="about us" />
                            </div>
                            <div class="about_col detail_section">

                                <h1>Multiplex and Shopping Malls</h1>

                                <p class="mentor_details">Multiplexes and shopping malls are the luxurious and leisure way to get out of the busy and hectic life. But in recent years, there have been numerous fire disaster cases that resulted in the tragic loss of human life in business buildings all over the world. Although malls and theatres with high density of crowd are bounded to follow the National Fire Safety Standards and all the required safety measures, Fire is an uninvited guest that should be prevented from the root.</p>

                                <p class="mentor_details">Once a fire starts, it might be challenging to contain it in locations like shopping malls and multiplexes. Preventing it from happening is the only effective kind of defence. Some of the ways are listed below:</p>

                            </div>
                        </div>
                        <div className="more_details">


                            <ul>
                                <li><span>Fire Safe Design Consideration</span> - A secure shopping mall or multiplex has to be able to utilise the majority of the commercially usable space with appealing designs while upholding safety regulations. When an accident occurs, people can safely evacuate thanks to a shopping mall's well-ventilated design and smoke extraction system. The risk of smoke and gas inhalation is reduced when there is an open atmosphere, making it simple and apparent for firefighters to evacuate individuals.</li>
                                <li><span>Fire Control Systems</span> - The most frequent safety precaution is to make sure the mall has a fire suppression system in place. The type of fire control system changes as the mall's capacity increases. The mall's fire control system needs to be modern and capable of quickly containing the fire to reduce the risk of human life. Every floor should include fire extinguishers that are easily accessible to the general public.</li>
                                <li><span>Fire Safety Curtains</span>- Shopping malls are frequently constructed to bring many brands together in one location. Due to the crowded nature of malls, safety precautions should be taken to prevent the spread of fire from one store to another. One such preventive precaution is the use of fire safety curtains, which impede the spread of fire and buy time for emergency services to respond.</li>
                                <li><span>Emergency Exit</span> - As a mall's capacity increases, the risk of evacuating people rises. When an accident occurs, people should be able to know where to flee. Everyone should be able to reach the emergency exit and be properly directed out. Additionally, fire safety equipment ought to be marked with an easy-to-read manual.</li>
                            </ul>

                        </div>
                        <div className="product-enquire-btn">
                            <button className='enqire_more_btn'><Link to="/contact-us" className='enquire-more'>Enquire</Link></button>
                        </div>
                    </div>
                </section>
                <Footer />
            </div>
        </>
    )
}

export default Multiplex;