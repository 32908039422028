import React from 'react';
import './Products.css';
import { Link } from 'react-router-dom';
import img1 from '../../images/Fire-alarm.jpg';
import img2 from '../../images/fire-fighting.jpg';
import img3 from '../../images/fire-sussepsion.jpg';
import img4 from '../../images/accessControl.jpg';
import img5 from '../../images/public_address.png';
import img6 from '../../images/cctv.jpg';
import img7 from '../../images/fire_extinguishers.jpg';
import img8 from '../../images/dust_sussepsion.jpg';
import smoke from '../../images/smoke.mp4';
import Footer from '../Footer';

export default function Products() {
  return (
    <>
      <div id="container">
        <div className="service">
          <video autoPlay muted loop>
            <source src={smoke} type="video/mp4" />
          </video>
          <h1>
            <span>O</span>
            <span>U</span>
            <span>R</span>
            &nbsp;
            <span>P</span>
            <span>R</span>
            <span>O</span>
            <span>D</span>
            <span>U</span>
            <span>C</span>
            <span>T</span>
            <span>S</span>
          </h1>
          <p>A wide range from top quality brands</p>
        </div>
      </div>

      <section className="product-row">

        <div className="product-image">
          <img src={img1} alt="" />
          <div className="details">
            <h2><span>Fire Alarm Systems</span></h2>
            <p>Fire Alarm Systems must be installed in every building to alert people to the presence of a fire using alarms or manual call points.</p>
            <div className="more">
              <button className='contact_btn read_more_btn'><Link to='/fire-alarm-systems' className='read-more'><span>Read More</span></Link></button>
            </div>
          </div>
        </div>

        <div className="product-image">
          <img src={img2} alt="" />
          <div className="details">
            <h2><span>Fire Fighting Systems</span></h2>
            <p>Automatic and manual firefighting devices should be used to protect ourselves and our property from fire.AFS  is a company that specializes...</p>
            <div className="more">
              <button className='contact_btn read_more_btn'><Link to="/fire-fighting-systems" className='read-more'><span>Read More</span></Link></button>
            </div>
          </div>
        </div>

        <div className="product-image">
          <img src={img3} alt="" />
          <div className="details">
            <h2><span>Fire Suppression Systems</span></h2>
            <p>Fire Suppression Systems are detection and security systems that can be installed in server rooms, phone rooms, archives, and other high-value areas.</p>
            <div className="more">
              <button className='contact_btn read_more_btn'><Link to="/fire-suppression-systems" className='read-more'><span>Read More</span></Link></button>
            </div>
          </div>
        </div>
        <div className="product-image">
          <img src={img4} alt="" />
          <div className="details">
            <h2><span>Access Control System</span></h2>
            <p>Access control systems are the electronic systems that are designed to control through a network and they should have an access to a network...</p>
            <div className="more">
              <button className='contact_btn read_more_btn'><Link to="/access-control-system" className='read-more'><span>Read More</span></Link></button>
            </div>
          </div>
        </div>
        <div className="product-image">
          <img src={img5} alt="" />
          <div className="details">
            <h2><span>Public Address System</span></h2>
            <p>A public address system (PA system) is an electronic system comprising microphones, amplifiers, loudspeakers,as well as other...</p>
            <div className="more">
              <button className='contact_btn read_more_btn'><Link to="/public-address-system" className='read-more'><span>Read More</span></Link></button>
            </div>
          </div>
        </div>

        <div className="product-image">
          <img src={img6} alt="" />
          <div className="details">
            <h2><span>CCTV Camera</span></h2>
            <p>Closed-circuit television (CCTV). The use of video cameras to relay a signal to a particular location, on a restricted collection of monitors, is known as video surveillance.</p>
            <div className="more">
              <button className='contact_btn read_more_btn'><Link to="/cctv-camera" className='read-more'><span>Read More</span></Link></button>
            </div>
          </div>
        </div>

        <div className="product-image">
          <img src={img7} alt="" />
          <div className="details">
            <h2><span>Fire Extinguishers</span></h2>
            <p>AFS specializes in the distribution of various forms of fire extinguishers (Automatic & Portable).The oxygen element is also...</p>
            <div className="more">
              <button className='contact_btn read_more_btn'><Link to="/fire-extinguishers" className='read-more'><span>Read More</span></Link></button>
            </div>
          </div>
        </div>
        <div className="product-image">
          <img src={img8} alt="" />
          <div className="details">
            <h2><span>Dust Suppression System</span></h2>
            <p>The Sprinkler System for Dust Suppression is basically designed to prevent liftoff of ...</p>
            <div className="more">
              <button className='contact_btn read_more_btn'><Link to="/dust-Suppression-system" className='read-more'><span>Read More</span></Link></button>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  )
}
