import React from 'react';
import './FireHazard.css';
import imges1 from '../../../images/system-design-engineering.jpeg';
import Footer from '../../Footer';
import { Link } from 'react-router-dom';

const SystemDesign = () => {
    return (
        <>
            <div className="service_main">
                <section class="services_detail">
                    <div class="about_us">
                        <div class="row2">
                            <div class="about_col">
                                <img src={imges1} alt="about us" />
                            </div>
                            <div class="about_col detail_section">

                                <h1>System Design & Engineering</h1>

                                <p class="mentor_details">The design of any fire-protection system is an exact science that takes into account a building’s use, occupancy, footprint, and even its other installed systems.</p>

                                <p class="mentor_details">Planning for fire protection involves an integrated approach in which system designers need to analyze building components as a total package. In most cases, the analysis needs to go beyond basic code compliance and the owner’s minimum legal responsibilities for providing protection.</p>

                                <h1>Code Compliance</h1>
                                <p class="mentor_details">Code compliance is the first objective in any design. Codes are legal minimum requirements; you have to meet the minimum with any design.</p>
                            </div>
                        </div>
                        <div className="more_details">
                            <h1> The Basics</h1>
                            <p>In the design process, these typical fire-protection system goals are on the table for consideration:</p>

                            <ul>
                                <li>Saving lives.</li>
                                <li>Saving property.</li>
                                <li>Preserving business continuity.</li>
                            </ul>
                            <p>It all depends on how a building is used and occupied. A warehouse or storage facility, for example, will have different fire-protection requirements than a multi-tenant office building.</p>
                            <p>While no standard fire-protection design blueprint exists for any two buildings, the systems found in any building typically include these basic components</p>
                            <ul>
                                <li>Detection.</li>
                                <li>Alarms and notification.</li>
                                <li>Suppression.</li>
                            </ul>
                        </div>
                        <div className="product-enquire-btn">
                            <button className='enqire_more_btn'><Link to="/contact-us" className='enquire-more'>Enquire</Link></button>
                        </div>
                    </div>
                </section>
                <Footer />
            </div>
        </>
    )
}

export default SystemDesign;