import React from 'react';
import './FireHazard.css';
import imges1 from '../../../images/annual-maintenance-contract.jpg';
import Footer from '../../Footer';
import { Link } from 'react-router-dom';

const AnnualMaintainance = () => {
    return (
        <>
            <div className="service_main">
                <section class="services_detail">
                    <div class="about_us">
                        <div class="row2">
                            <div class="about_col">
                                <img src={imges1} alt="about us" />
                            </div>
                            <div class="about_col detail_section">

                                <h1>Annual Maintenance Contract</h1>

                                <p class="mentor_details">Inspection and maintenance services ensure that your fire protection equipment will operate properly when you need it most. AFS Offers a well-trained service department fully equipped to meet our client’s maintenance and repair needs.</p>

                                <p class="mentor_details">Regular, periodic inspection and testing is the best defense against system failure so that you may continue to operate your business securely and efficiently.</p>

                                <p class="mentor_details">AFS offers complete inspection and testing services for fire extinguishers, fire-Fighting Systems, Fire Suppression systems, Access Control systems, CCTV, Public Addressable systems.</p>
                            </div>
                        </div>
                        <div className="more_details">
                            <p>Some of the many inspection and testing services we offer: </p>
                            <ul>
                                <li>Annual inspection and recommended testing of all fire extinguishers.</li>
                                <li>Regular periodic inspection, testing, and maintenance on all types of fire protection systems</li>
                                <li> Regular inspection of all special hazard suppression systems including explosion suppression</li>
                                <li>Inspection reports transmitted electronically to the customer</li>
                                <li>Annual service plan automatically schedules system inspections well in advance of the expiration date</li>
                                <li>Barcoding of fire extinguishers for record-keeping and inventory control</li>
                                <li>Preserving business continuity.</li>
                            </ul>
                            <p>AFS has built its reputation on providing superior customer service.  We specialize in the inspection and maintenance of a variety of fire protection equipment and systems.  Speak with a professional at AFS today in order to schedule your next service.</p>
                        </div>
                        <div className="product-enquire-btn">
                            <button className='enqire_more_btn'><Link to="/contact-us" className='enquire-more'>Enquire</Link></button>
                        </div>
                    </div>

                </section>
                <Footer />
            </div>
        </>
    )
}

export default AnnualMaintainance;