import React from 'react';
import './FireHazard.css';
import imges1 from '../../../images/Fire-Risk.jpg';
import Footer from '../../Footer';
import { Link } from 'react-router-dom';

const FireAnalysis = () => {
    return (
        <>
            <div className="service_main">
                <section class="services_detail">
                    <div class="about_us">
                        <div class="row2">
                            <div class="about_col">
                                <img src={imges1} alt="about us" />
                            </div>
                            <div class="about_col detail_section">

                                <h1>Fire Risk Assessment</h1>

                                <p class="mentor_details">AFS offers risk management consulting services including the development and completion of Fire Risk Assessments (FRA), where required by an authority having jurisdiction (AHJ) or for use as a design basis.</p>

                                <p class="mentor_details">Fire risk assessments are typically used to validate performance-based solutions, studies, code equivalencies, and regulatory compliance evaluations. Fire risk assessments and analyses should always be completed by experienced professionals with a documented background in the subject matter.</p>

                                <p class="mentor_details">Our professional engineering and fire protection consulting staff have the educational knowledge and years of experience required to prepare and conduct a valid fire risk assessment for any facility type.</p>
                            </div>
                        </div>
                        <div className="product-enquire-btn">
                            <button className='enqire_more_btn'><Link to="/contact-us" className='enquire-more'>Enquire</Link></button>
                        </div>
                    </div>
                </section>
                <Footer />
            </div>
        </>
    )
}

export default FireAnalysis;