import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';
// import logo from "../images/AFS_Logo.jpeg";
import indiaMart from '../images/india-mart.jpeg';
import justDial from '../images/just-dial.jpg';
// import Brochure from '../images/brouchure.pdf';
const Footer = () => {
  return (
    <>

      <footer>
        <div className="footer_top">
          <div className="waves">
            <div className="wave" id="wave1"></div>
            <div className="wave" id="wave2"></div>
            <div className="wave" id="wave3"></div>
            <div className="wave" id="wave4"></div>
          </div>
          <div className="AFS_logo">
            <h1>Abhi Fire Safety Services LLP</h1>
            <p>Set ablaze your worries on safety</p>
          </div>
          <ul className="social_icons">
            <li><a target="_blank" rel="noreferrer" href="https://g.page/r/CRzHA-oIFdJ3EAE"><ion-icon name="logo-google"></ion-icon></a></li>
            <li><a target="_blank" rel="noreferrer" href="https://www.facebook.com/abhifiresafety"><ion-icon name="logo-facebook"></ion-icon></a></li>
            <li><a target="_blank" rel="noreferrer" href="https://g.page/r/CRzHA-oIFdJ3EAE"><ion-icon name="location-outline"></ion-icon></a></li>
            <li><a target="_blank" rel="noreferrer" href="https://www.instagram.com/abhifiresafety/?hl=en"><ion-icon name="logo-instagram"></ion-icon></a></li>
            <li><a target="_blank" rel="noreferrer" href="https://www.linkedin.com/company/abhifiresafety/"><ion-icon name="logo-linkedin"></ion-icon></a></li>
            <li><a target="_blank" rel="noreferrer" href="https://twitter.com/abhifiresafety"><ion-icon name="logo-twitter"></ion-icon></a></li>
            <li><a target="_blank" rel="noreferrer" href="https://www.indiamart.com/abhi-fire-safety-services-llp/"><img src={indiaMart} alt="" /></a></li>
            <li><a target="_blank" rel="noreferrer" href="https://www.justdial.com/jdmart/Jamshedpur/Abhi-Fire-Safety-Services-LLP-Near-Vijya-Garden-Birsanagar/0657PX657-X657-210109212015-B4P7_BZDET/catalogue"><img src={justDial} alt="" /></a></li>
          </ul>
        </div>
        <div className="footer_bottom">
          <div className="footer_container">
            <div className="footer_sec footer_aboutus">
              <h2>About Us</h2>
              <p>Abhi Fire Safety service LLP was founded to satisfy a need for specialized engineering expertise in the field of fire protection. For all industries and clients, including heavy industry, manufacturers, architects, contractors, developers, fire marshals, and other design professionals.</p>
              <a href="https://drive.google.com/file/d/17kwlGVZ7ZBrZaKBVdkzi66cp6QJe9qkI/view" target="_blank" rel="noreferrer" className="download-btn">Download</a>
            </div>
            <div className="footer_sec footer_quicklinks">
              <h2>Important Links</h2>
              <ul>
                <li><Link to='/' className="footer_links" >Home</Link></li>
                <li><Link to='/products' className="footer_links">Products</Link></li>
                <li><Link to='/services' className="footer_links">Services</Link></li>
                <li><Link to='/industries' className="footer_links">Industries</Link></li>
                <li><Link to='/client' className="footer_links">Clients</Link></li>
                <li><Link to='/career' className="footer_links">Careers</Link></li>
                <li><Link to='/aboutus' className="footer_links">About Us</Link></li>
                <li><Link to='/contact-us' className="footer_links">Contact Us</Link></li>
              </ul>
            </div>
            <div className="footer_sec footer_contact">
              <h2>Contact Info</h2>
              <ul className="footer_info">
                <li>
                  <span><ion-icon name="location-outline"></ion-icon></span>
                  <span><a href="https://g.page/r/CRzHA-oIFdJ3EAE" target="_blank" rel="noreferrer">House No - A44, Vijay Nagar, Golmuri ( Back side of Akash Deep Plaza), Jamashedpur, Jharkhand, PIN - 831003</a></span>
                </li>
                <li>
                  <span><ion-icon name="call-outline"></ion-icon></span>
                  <p><a href="tel:+91 7979737168"> Mob: +91 7979737168</a></p>
                </li>
                <li>
                  <span><ion-icon name="mail-outline"></ion-icon></span>
                  <p><a href="mailto: info@abhifiresafety.com">info@abhifiresafety.com</a></p>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <p className="footer_copyright">Copyright &copy;2022 - 2023 All rights reserved by Abhi Fire Safety Services LLP Developed by Lavish Garg (<a target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/lavishgargnitj"><ion-icon name="logo-linkedin"></ion-icon></a>) & Anubhav Banerjee (<a target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/anubhav-banerjee-646724199/"><ion-icon name="logo-linkedin"></ion-icon></a>)</p>
      </footer>
    </>
  )
}

export default Footer;