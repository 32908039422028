import React from 'react';
import './CommercialBuildings.css';
// import imges1 from '../../../images/system-design-engineering.jpeg';
import industry6 from '../../../images/09.jpg';
import Footer from '../../Footer';
import { Link } from 'react-router-dom';

const School = () => {
    return (
        <>
            <div className="service_main">
                <section class="services_detail">
                    <div class="about_us">
                        <div class="row2">
                            <div class="about_col">
                                <img src={industry6} alt="about us" />
                            </div>
                            <div class="about_col detail_section">

                                <h1>Schools and Hospitals</h1>

                                <p class="mentor_details">Healthcare facilities such as hospitals and nursing homes normally see a continuous flow of people. Hospitals are not only required to take care of patients but must ensure that their safety is prioritised. Fire accidents at healthcare facilities should not force anyone to run for their life. Similarly, schools need to take utmost care for their students.</p>

                                <p class="mentor_details">The infrastructure of Schools and Hospitals houses many heat-dissipating equipment, combustible gases and fuels, chemicals, electrical wiring and the like. They can follow a three-step approach to minimize the risk caused due to fire:</p>

                            </div>
                        </div>
                        <div className="more_details">

                            <ul>
                                <li><span>Prevention</span> - Prevention implies that all the electrical points and wires are handled properly. No connections should be loose and overloading should be taken care of. The combustible material should not be kept near to electrical circuits.</li>
                                <li><span>Protection</span> - This step is to create infrastructure for emergencies. The fire detection and suppression system including smoke detector, fire alarm and sprinkler system should be in place. Right kind of fire extinguishers should be deployed at right locations. The Public Address (PA) System should also be in proper functioning condition.</li>
                                <li><span>Preparation</span> - Regular training should be conducted for staff and students. This can be done through mock drills which make the stakeholders familiar with the equipment and well as action plan during the contingency. Also, the equipment should be checked time to time to ensure that it is in the working condition.</li>

                            </ul>

                        </div>
                        <div className="product-enquire-btn">
                            <button className='enqire_more_btn'><Link to="/contact-us" className='enquire-more'>Enquire</Link></button>
                        </div>
                    </div>
                </section>
                <Footer />
            </div>
        </>
    )
}

export default School;