import React from 'react';
import './Career.css'
import career_Img from '../../images/careerImg.jpg'
import Footer from '../Footer';
import './Services/MainPage.css';
import smoke from '../../images/smoke.mp4';
import emailjs from 'emailjs-com';

export default function Career() {

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm(
      // 'service_ehs1o33',
      // 'template_xzhbhsf',
      // e.target,
      // '-T898Jed3jn6Bhtft'
      'service_career_page',
      'template_25jvuev',
      e.target,
      'vb7Y17NKH-o6oeVNN'
    ).then(res => {
      alert("Message Sent Successfully");
      document.getElementById('myform').reset();
    }).catch(err => console.log(err));
  }

  return (
    <>
      <div id="container">
        <div className="service">
          <video autoPlay muted loop>
            <source src={smoke} type="video/mp4" />
          </video>
          <h1>
            <span>C</span>
            <span>A</span>
            <span>R</span>
            <span>E</span>
            <span>E</span>
            <span>R</span>
          </h1>
          <p>Grow with us</p>
        </div>
      </div>
      <div className="Career-form">
        <div className="inner-form">
          <div className="image-holder">
            <img src={career_Img} alt="" />
          </div>
          <form action="" onSubmit={sendEmail} id="myform">
            <h3>Join Us</h3>
            <div className="form-group">
              <input type="text" placeholder='First Name *' className='form-control' name="name" required="required" />
              <input type="text" placeholder='Last Name' className='form-control' />
            </div>
            <div className="form-wrapper">
              <input type="text" placeholder='Email Address *' className='form-control' name="email" required="required" />
            </div>
            <div className="form-wrapper">
              <select name="gender" id="" className='form-control' >
                <option value="" disabled selected >Gender</option>
                <option value="male" >Male</option>
                <option value="female">Female</option>
                <option value="other">Other</option>
              </select>
            </div>
            <div className="form-wrapper">
              <select name="qualification" id="" className='form-control'>
                <option value="" disabled selected>Highest Qualification</option>
                <option value="10th" >10th</option>
                <option value="12th">12th</option>
                <option value="Diploma">Diploma</option>
                <option value="Graduation">Graduation</option>
                <option value="Post-Graduation">Post-Graduation</option>
              </select>
            </div>
            <div className="form-wrapper">
              <input type="text" placeholder='Phone Number' className='form-control' name="phone" />
            </div>

            <div className="form-wrapper">
              <p>Upload your CV here (upto 1MB)</p>
              <input type="file" id="myFile" name="filename" className='form-control' />
              {/* <a href="https://forms.gle/wzUTXiNKxQDkJAmc9">Upload File</a> */}
            </div>
            <input className='career-btn' type="submit" value="Submit" />
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
}
