const MenuItemsProducts = [
    {
        title: 'Fire Alarm Systems',
        path: '/fire-alarm-systems',
        cName: 'dropdown-link',        
    },
    {
        title: 'Fire Fighting Systems',
        path: '/fire-fighting-systems',
        cName: 'dropdown-link',        
    },
    {
        title: 'Fire Suppression Systems',
        path: '/fire-suppression-systems',
        cName: 'dropdown-link',        
    },
    {
        title: 'Access Control System',
        path: '/access-control-system',
        cName: 'dropdown-link',        
    },
    {
        title: 'Public Address System',
        path: '/public-address-system',
        cName: 'dropdown-link',        
    },
    {
        title: 'CCTV Camera',
        path: '/cctv-camera',
        cName: 'dropdown-link',        
    },
    {
        title: 'Fire Extinguishers',
        path: '/fire-extinguishers',
        cName: 'dropdown-link',        
    },
    {
        title: 'Dust Suppression System',
        path: '/dust-Suppression-system',
        cName: 'dropdown-link',        
    }
  ];

  export default MenuItemsProducts;