import React from 'react';
import '../Services/MainPage.css';
import smoke from '../../../images/smoke.mp4';
import './ContactUs.css';
import Footer from '../../Footer';
import { Link } from 'react-router-dom';
import emailjs from 'emailjs-com';

const ContactUs = () => {


  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm(
      // 'service_ehs1o33',
      // 'template_xzhbhsf',
      // e.target,
      // '-T898Jed3jn6Bhtft'
      'service_contact_form',
      'template_exjur6h',
      e.target,
      'vb7Y17NKH-o6oeVNN'
    ).then(res => {
      alert("Message Sent Successfully");
      document.getElementById('myform').reset();
    }).catch(err => console.log(err));
  }


  return (
    <>
      <div id="container">
        <div className="service">
          <video autoPlay muted loop>
            <source src={smoke} type="video/mp4" />
          </video>
          <h1>
            <span>C</span>
            <span>O</span>
            <span>N</span>
            <span>T</span>
            <span>A</span>
            <span>C</span>
            <span>T</span>
            &nbsp;
            <span>U</span>
            <span>S</span>
          </h1>
          <p>Get all your queries resolved</p>
        </div>
      </div>
      <div className="contact_details_section">
        <h1>GET IN TOUCH</h1>
        <h1>Abhi Fire Safety Services LLP</h1>
        <p>Corporate Office Address</p>
        <div className="contact_detail">
          <div className="contact_phone">
            <ion-icon name="call-outline"></ion-icon>
            <p><a href="tel:+91 9341527028">+91 9341527028</a></p>
          </div>
          <div className="contact_address">
            <ion-icon name="location-outline"></ion-icon>
            <p>House No - A44, Vijay Nagar , Golmuri <br />( Back side of Akash Deep Plaza), Jamashedpur, Jharkhand, PIN - 831003
            </p>
          </div>
          <div className="contact_email">
            <ion-icon name="mail-outline"></ion-icon>
            <p><a href="mailto: info@abhifiresafety.com">info@abhifiresafety.com</a></p>
          </div>
        </div>
      </div>


      <section className="contact_form_area">
        <div className="contact_form_container">
          <div className="contact_map">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3678.1538450479793!2d86.25940301427802!3d22.79676263045179!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39f609c9349cc50b%3A0x77d21508ea03c71c!2sAbhi%20Fire%20Safety%20Services%20LLP-Best%20Fire%20Alarm%20System%2F%20Fire%20Protection%20System%20%2FFire%20Sprinkler%20System!5e0!3m2!1sen!2sin!4v1656609614532!5m2!1sen!2sin" title="Cooperate Office Address"></iframe>
          </div>
          <div className="contact_form">
            <h2>Contact Us</h2>
            <p>(Don't Worry! We won't spam you)</p>
            {/* ################# Contact us form row... ################### */}
            <div className="row100">
              {/* ############### Name Input field... ########### */}
              <form action="" id="myform" onSubmit={sendEmail}>
                <div className="contact_col">
                  <div className="input_box">
                    <input type="text" name="name" required="required" placeholder="Name *" />
                    {/* <span className="input_text">Name <span className="required_imp">*</span></span> */}
                    <span className="input_line"></span>
                  </div>
                </div>

                {/* ############ Email input field ################### */}
                <div className="contact_col">
                  <div className="input_box">
                    <input type="email" name="email" placeholder="Email *" required="required" />
                    {/* <span className="input_text">Email <span className="required_imp">*</span></span> */}
                    <span className="input_line"></span>
                  </div>
                </div>

                {/* ################## Phone input field ################ */}
                <div className="contact_col">
                  <div className="input_box">
                    <input type="phone" name="phone" placeholder="Phone Number" />
                    {/* <span className="input_text">Phone Number <span className="required_imp">*</span></span> */}
                    <span className="input_line"></span>
                  </div>
                </div>

                {/* ################## organisation field...###############*/}
                <div className="contact_col">
                  <div className="input_box">
                    <input type="text" name="organisation" placeholder='Organisation' />
                    {/* <span className="input_text">Organisation</span> */}
                    <span className="input_line"></span>
                  </div>
                </div>

                {/* ############## text Area field..###################3 */}
                <div className="contact_col">
                  <div className="input_box contact_text_area">
                    <textarea placeholder="Type your message here..." name="message"></textarea>
                    {/* <span className="input_text">Type your Message Here...</span> */}
                    <span className="input_line"></span>
                  </div>
                </div>

                <div className="contact_col">
                  <div className="input_box">
                    <input type="submit" value="Send" />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>

      <div className="Branches_office_section">
        <h1>Branch Office</h1>
        <div className="Branch_office">
          <div className="contact_phone">
            <h1>Ranchi</h1>
            <p>Shop No – 206,  Blue Diamond Apartment,
              Hesag Chowk, Ranchi, Jharkhand 834003
            </p>
            <Link to='/ranchi-office' className="contact_link">Visit Page...</Link>
          </div>
          <div className="contact_address">
            <h1>Patna</h1>
            <p>Abhi House, 1st Floor, 90 Feet Road,
              Near Bhagawat Milan Mandir,
              Bhagawat Nagar, Patna, Bihar
            </p>
            <Link to='/patna-office' className="contact_link">Visit Page...</Link>
          </div>
          <div className="contact_email">
            <h1>Delhi NCR</h1>
            <p>SD-A-09, Sadarpur, Sector-45, Noida, Uttar Pradesh, 201301</p>
            <Link to='/delhi-office' className="contact_link">Visit Page...</Link>
          </div>
        </div>
      </div>

      <Footer />
    </>
  )
}

export default ContactUs;
