import React from 'react'
import './product_main.css';
import img1 from '../../../images/fire_extinguishers.jpg';
import Footer from '../../Footer';
import { Link } from 'react-router-dom';
const FireExtinguishers = () => {
  return (
    <>
      <div className="service_main">
        <section className="services_detail">
          <div className="about_us">
            <div className="row2">
              <div className="about_col">
                <img src={img1} alt="about us" />
              </div>
              <div className="about_col detail_section">

                <h1>Fire Extinguishers</h1>

                <p className="mentor_details">AFS specializes in the distribution of various forms of fire extinguishers (Automatic & Portable).</p>

                <ul className="points">
                  <li >Water and Foam</li>
                  <li >Carbon Dioxide   </li>
                  <li >Dry Chemical</li>
                  <li >Wet Chemical</li>
                  <li >Clean Agent</li>
                  <li >Dry Powder</li>
                  <li >Water Mist</li>
                  <li >Cartridge Operated Dry Chemical</li>
                </ul>
              </div>
            </div>
            <div className="more_details">
              <h1>Water and Foam</h1>
              <p>Extinguishers made of water or foam extinguish the fire by removing the heat part from the fire triangle. The oxygen element is also removed from the other elements by foam agents.</p>

              <p>Water extinguishers should only be used to put out Class A fires; they should not be used to put out Class B or C fires. In a Class B fire, the discharge stream can carry the flammable liquid or create a shock hazard in a Class C fire.</p>

              <h1>Carbon Dioxide</h1>
              <p>Carbon dioxide fire extinguishers put out fires by removing the oxygen component of the fire triangle as well as the heat with a very cold discharge. Carbon dioxide can be used on Class B & C fires. They are usually ineffective on Class A fires.</p>

              <h1>Dry Chemical</h1>
              <p>Dry chemical fire extinguishers put out a fire specifically by interrupting the fire triangle’s chemical reaction.</p>
              <p> The multipurpose dry chemical fire extinguisher, which is effective on Class A, B, and C fires, is the most commonly used form of fire extinguisher today. On Class A fires, this agent also acts by providing a barrier between the oxygen and the fuel.</p>
              <p>Dry chemical is only for Class B and C fires. It’s important to use the required extinguisher in the form of fuel! Using the incorrect agent will allow a fire to re-ignite after it has appeared to be extinguished.</p>

              <h1>Wet Chemical</h1>
              <p>Wet Chemical is a modern extinguishing agent that eliminates the heat from the fire triangle and prevents re-ignition by creating a barrier between the oxygen and fuel elements.</p>
              <p>For new, high-efficiency deep fat fryers in commercial cooking operations, wet chemical Class K extinguishers were developed. Some can also be used in industrial kitchens to put out Class A fires.</p>

              <h1>Clean Agent</h1>
              <p>Extinguishers that are halogenated or Clean Agents contain halon agents as well as newer, less ozone-depleting halocarbon agents. They put out the fire by interrupting the fire triangle’s chemical reaction.</p>
              <p>Clean agent extinguishers are usually used to put out Class B and C fires. On Class A, B, and C fires, some larger clean agent extinguishers can be used.</p>

              <h1>Dry Powder</h1>
              <p>Dry powder extinguishers are similar to dry chemical extinguishers, except that they extinguish the fire by removing the fuel from the oxygen element or by eliminating the heating element of the fire triangle.</p>
              <p>Dry powder extinguishers, on the other hand, are only for Class D or combustible metal fires. All other types of fires are ineffective with them.</p>

              <h1>Water Mist</h1>
              <p>Water mist extinguishers are a relatively new invention that puts out a fire by removing the heat factor from the fire triangle. When pollution is a problem, they are an alternative to clean agent extinguishers.</p>
              <p>Water mist extinguishers are usually used to put out Class A fires, but they can also be used to put out Class C fires.</p>

              <h1>Cartridge Operated Dry Chemical</h1>
              <p>Cartridge-operated dry chemical fire extinguishers put out the fire by interrupting the fire triangle’s chemical reaction.</p>
              <p>The multipurpose dry chemical extinguisher, like the stored pressure dry chemical extinguishers, is effective on Class A, B, and C fires. On Class A fires, this agent also acts by creating a barrier between the oxygen and the fuel.</p>
              <p>Dry chemical is only for Class B and C fires. It’s important to use the required extinguisher in the form of fuel! Using the wrong agent will cause a fire to re-ignite after it appears to have been extinguished.</p>
            </div>
            <div className="product-enquire-btn">
              <button className='enqire_more_btn'><Link to="/contact-us" className='enquire-more'>Enquire</Link></button>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </>
  )
}

export default FireExtinguishers
