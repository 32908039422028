import React from 'react';
import './FireHazard.css';
import imges1 from '../../../images/installation.jpg';
import Footer from '../../Footer';
import { Link } from 'react-router-dom';

const Installation = () => {
    return (
        <>
            <div className="service_main">
                <section class="services_detail">
                    <div class="about_us">
                        <div class="row2">
                            <div class="about_col">
                                <img src={imges1} alt="about us" />
                            </div>
                            <div class="about_col detail_section">

                                <h1>Installation & Commissioning</h1>

                                <p class="mentor_details">Fire Alarm System Commissioning is defined in BS5839-1 as the process which involves thorough testing of an installed system, to ensure it operates correctly in accordance with the recommendations of the standard and project specification.</p>

                                <p class="mentor_details">At AFS our engineering staff are time served, highly qualified technicians.</p>

                                <p class="mentor_details"> Our team of engineers has all been involved in programming fire alarm panels with complex cause and effect on many different systems; with this in mind, you can rest assured we can cope with any programming requirements you may have to complement the mandatory testing.</p>
                            </div>
                        </div>
                        <div className="product-enquire-btn">
                            <button className='enqire_more_btn'><Link to="/contact-us" className='enquire-more'>Enquire</Link></button>
                        </div>

                    </div>
                </section>
                <Footer />
            </div>
        </>
    )
}

export default Installation;